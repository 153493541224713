import agent from '../../api/agent';

export default {
  fetchSettings: async ({ commit }) => {
    try {
      const result = await agent.settings.all();
      commit('SET_SETTINGS', result);
    } catch (error) {
      console.log(error);
    }
  },
};
