import agent from '../../api/agent';

export default {
  fetchStores: async ({ commit }) => {
    try {
      const stores = await agent.stores.all();
      stores.forEach((store) => {
        store.selected = false;
        store.display = true;
      });
      commit('SET_STORES', stores);
    } catch (error) {
      console.log(error);
    }
  },
};
