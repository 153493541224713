export default {
  homeText: (state, getters) =>
    state.homeText ? state.homeText[`language${getters.selectedLanguage}`] : 'Welcome to Reserve Your Reward',

  label: (state, getters) => (label) => {
    const text = state.texts?.find((text) => text?.label === label);

    if (text && text[`language${getters?.selectedLanguage}`]) {
      return text[`language${getters?.selectedLanguage}`];
    }

    console.log('No text found for label: ', label);
    console.log('For language: ', getters?.selectedLanguage);
    return label;
  },

  submit: (state, getters) => getters.label('Submit'),
  productCatalog: (state, getters) => getters.label('Product catalog'),
  amount: (state, getters) => getters.label('Amount'),
  stamps: (state, getters) => getters.label('Stamps'),
  orderProductButton: (state, getters) => getters.label('Order product button'),
  frequentlyAskedQuestions: (state, getters) => getters.label('Frequently asked questions'),
  faqSearchText: (state, getters) => getters.label('Faq search text'),
  searchInputPlaceholder: (state, getters) => getters.label('Search input placeholder'),
  searchButton: (state, getters) => getters.label('Search button'),
  noResultsFound: (state, getters) => getters.label('No Results Found'),
  homeTitle: (state, getters) => getters.label('Home title'),
  browseCatalog: (state, getters) => getters.label('Browse catalog'),
  viewAllProducts: (state, getters) => getters.label('View all products'),
  customerService: (state, getters) => getters.label('Customer service'),
  myOrder: (state, getters) => getters.label('My order'),
  myAccount: (state, getters) => getters.label('My account'),
  selectedProducts: (state, getters) => getters.label('Selected products'),
  amountOfProducts: (state, getters) => getters.label('Amount of products'),
  totalOrder: (state, getters) => getters.label('Total order'),
  indicatesRequiredField: (state, getters) => getters.label('Indicates a required field'),
  backToProductCatalog: (state, getters) => getters.label('Back to product catalog'),
  proceed: (state, getters) => getters.label('Proceed'),
  isRequired: (state, getters) => getters.label('is required'),
  notAvailable: (state, getters) => getters.label('Not available'),
  outOfStock: (state, getters) => getters.label('Out of stock'),
  notify: (state, getters) => getters.label('Notify'),
  fillInYourEmail: (state, getters) => getters.label('Fill in your email address'),
  emailAddress: (state, getters) => getters.label('Email address'),
  validEmailAddress: (state, getters) => getters.label('Valid email address'),
  digitalCollector: (state, getters) => getters.label('Digital collector'),
  createYourAccount: (state, getters) => getters.label('Create your account'),
  pleaseFillInYourName: (state, getters) => getters.label('Please fill in your name'),
  firstName: (state, getters) => getters.label('First name'),
  lastName: (state, getters) => getters.label('Last name'),
  loyaltyCardNumber: (state, getters) => getters.label('Loyalty card number'),
  alreadyHaveAnAccount: (state, getters) => getters.label('Already have an account'),
  nextStep: (state, getters) => getters.label('Next step'),
  previousStep: (state, getters) => getters.label('Previous step'),
  hello: (state, getters) => getters.label('Hello'),
  weNeedYourEmailaddress: (state, getters) => getters.label('We need your emailaddress'),
  confirmEmailaddress: (state, getters) => getters.label('Confirm email address'),
  phoneNumber: (state, getters) => getters.label('Phone number'),
  mobileNumber: (state, getters) => getters.label('Mobile number'),
  incorrectEmailAddressConfirmation: (state, getters) => getters.label('Incorrect email address confirmation'),
  createYourPassword: (state, getters) => getters.label('Create your password'),
  needToCreateAPassword: (state, getters) => getters.label('You need to create a password'),
  password: (state, getters) => getters.label('Password'),
  confirmPassword: (state, getters) => getters.label('Confirm password'),
  accept: (state, getters) => getters.label('Accept'),
  incorrectPasswordConfirmation: (state, getters) => getters.label('Incorrect password confirmation'),
  passwordMustBeStronger: (state, getters) => getters.label('Password must be stronger'),
  termsAndConditions: (state, getters) => getters.label('Terms and conditions'),
  privacyPolicy: (state, getters) => getters.label('Privacy policy'),
  notAllRequiredFieldsAreCompleted: (state, getters) => getters.label('Not all required fields are completed'),
  thankYou: (state, getters) => getters.label('Thank you'),
  yourOrderIsConfirmed: (state, getters) => getters.label('Your order is confirmed'),
  viewMyOrder: (state, getters) => getters.label('View my order'),
  orderProcess: (state, getters) => getters.label('Order process'),
  reservationPeriod: (state, getters) => getters.label('Reservation period'),
  currentStep: (state, getters) => getters.label('Current step'),
  orderIsBeingProcessed: (state, getters) => getters.label('Order is being processed'),
  readyForPickUp: (state, getters) => getters.label('Ready for pick-up'),
  youCanMakeChangeOrCancelAReservationAtThisTime: (state, getters) =>
    getters.label('You can make, change, or cancel a reservation at this time'),
  changeMyStore: (state, getters) => getters.label('Change my store'),
  changeMyOrder: (state, getters) => getters.label('Change my order'),
  cancelMyOrder: (state, getters) => getters.label('Cancel my order'),
  lastUpdated: (state, getters) => getters.label('Last updated'),
  totalAmountOfProducts: (state, getters) => getters.label('Total amount of products'),
  selectedStore: (state, getters) => getters.label('Selected store'),
  total: (state, getters) => getters.label('Total'),
  yourOrderIsBeingProcessed: (state, getters) => getters.label('Your order is being processed'),
  yourOrderIsReadyForPickup: (state, getters) => getters.label('Your order is ready for pickup'),
  areYouSure: (state, getters) => getters.label('Are you sure?'),
  yourItemsWillNoLongerBeReserved: (state, getters) => getters.label('Your items will no longer be reserved'),
  yes: (state, getters) => getters.label('Yes'),
  no: (state, getters) => getters.label('No'),
  noOrderFound: (state, getters) => getters.label('No order found'),
  continueShopping: (state, getters) => getters.label('Continue shopping'),
  personalInformation: (state, getters) => getters.label('Personal information'),
  updateAccountDetails: (state, getters) => getters.label('Update account details'),
  currentPassword: (state, getters) => getters.label('Current password'),
  newPassword: (state, getters) => getters.label('New password'),
  confirmNewPassword: (state, getters) => getters.label('Confirm new password'),
  updatePassword: (state, getters) => getters.label('Update password'),
  deleteAccount: (state, getters) => getters.label('Delete account'),
  toDeleteYourAccountClickTheButtonBelow: (state, getters) =>
    getters.label('To delete your account click the button below'),
  deleteMyAccount: (state, getters) => getters.label('Delete my account'),
  forgotMyPassword: (state, getters) => getters.label('Forgot my password'),
  pleaseLoginToViewOrderDetails: (state, getters) => getters.label('Please login to view order details'),
  clickHere: (state, getters) => getters.label('Click here'),
  login: (state, getters) => getters.label('Login'),
  logout: (state, getters) => getters.label('Logout'),
  forgotYourPassword: (state, getters) => getters.label('Forgot your password'),
  fillInTheFormBelowToRequestANewPassword: (state, getters) =>
    getters.label('Fill in the form below to request a new password'),
  sendPassword: (state, getters) => getters.label('Send password'),
  pleaseLeaveYourInformation: (state, getters) => getters.label('Please leave your information'),
  name: (state, getters) => getters.label('Name'),
  send: (state, getters) => getters.label('Send'),
  orderNumber: (state, getters) => getters.label('Order number'),
  question: (state, getters) => getters.label('Question'),
  yourCartIsEmpty: (state, getters) => getters.label('Your cart is empty'),
  noProductsInShoppingList: (state, getters) => getters.label('No products in shopping list'),
  chooseStore: (state, getters) => getters.label('Choose store'),
  pleaseChooseTheStore: (state, getters) => getters.label('Please choose the store'),
  stores: (state, getters) => getters.label('Stores'),
  allStores: (state, getters) => getters.label('All stores'),
  nearestStores: (state, getters) => getters.label('Nearest stores'),
  productDetails: (state, getters) => getters.label('Product details'),
  youHaveNotSelectedAnyProducts: (state, getters) => getters.label('You have not selected any products'),
  addAtLeastOneItemToYourShoppingList: (state, getters) => getters.label('Add at least one item to your shopping list'),
  thisActionCannotBeUndone: (state, getters) => getters.label('This action cannot be undone'),
  yourPasswordHasBeenUpdated: (state, getters) => getters.label('Your password has been updated'),
  yourPersonalDataHasBeenUpdated: (state, getters) => getters.label('Your personal data has been updated'),
  addMoreProducts: (state, getters) => getters.label('Add more products'),
  save: (state, getters) => getters.label('Save'),
  remove: (state, getters) => getters.label('Remove'),
  yourRequestHasBeenSent: (state, getters) => getters.label('Your request has been sent'),
  cookiePolicy: (state, getters) => getters.label('Cookie policy'),
  pageNotFound: (state, getters) => getters.label('Page not found'),
  thisPageIsNotAvailable: (state, getters) => getters.label('This page is not available'),
  backToHomepage: (state, getters) => getters.label('Back to homepage'),
  createReservation: (state, getters) => getters.label('Create reservation'),
  lookupReservation: (state, getters) => getters.label('Lookup reservation'),
  status: (state, getters) => getters.label('Status'),
  orderList: (state, getters) => getters.label('Order list'),
  storeOverview: (state, getters) => getters.label('Store overview'),
  orderDump: (state, getters) => getters.label('Order dump'),
  ordersPerStore: (state, getters) => getters.label('Orders per store'),
  findAnOrder: (state, getters) => getters.label('Find an order'),
  reservation: (state, getters) => getters.label('Reservation'),
  back: (state, getters) => getters.label('Back'),
  reservationDate: (state, getters) => getters.label('Reservation date'),
  reservationNumber: (state, getters) => getters.label('Reservation number'),
  reservedProducts: (state, getters) => getters.label('Reserved products'),
  quantity: (state, getters) => getters.label('Quantity'),
  pickUpLocation: (state, getters) => getters.label('Pick-up location'),
  storeName: (state, getters) => getters.label('Store name'),
  address: (state, getters) => getters.label('Address'),
  zipCode: (state, getters) => getters.label('Zipcode'),
  city: (state, getters) => getters.label('City'),
  printOrder: (state, getters) => getters.label('Print order'),
  resendConfirmation: (state, getters) => getters.label('Resend confirmation'),
  currentStatus: (state, getters) => getters.label('Current status'),
  totalRewardsReserved: (state, getters) => getters.label('Total rewards reserved'),
  placedOrders: (state, getters) => getters.label('Placed orders'),
  canceledOrders: (state, getters) => getters.label('Canceled orders'),
  totalUsers: (state, getters) => getters.label('Total users'),
  download: (state, getters) => getters.label('Download'),
  pleaseCheckYourInbox: (state, getters) => getters.label('Please check your inbox'),
  passwordMinLength: (state, getters) => getters.label('Minimum length is 8 characters'),
  resetPassword: (state, getters) => getters.label('Reset password'),
  invalidSpecialCharacters: (state, getters) => getters.label('Special characters not allowed'),
  OptInRecieveEmail: (state, getters) => getters.label('OptIn recieve email'),
  totalStamps: (state, getters) => getters.label('Total stamps'),
  totalPrice: (state, getters) => getters.label('Total price'),
  free: (state, getters) => getters.label('Free'),
  today: (state, getters) => getters.label('Today'),
  baseline: (state, getters) => getters.label('Baseline'),
  editOrder: (state, getters) => getters.label('Edit order'),
  pickedUp: (state, getters) => getters.label('Picked up'),
};
