import agent from '../../api/agent';

export default {
  fetchProduct: async ({ commit }, id) => {
    commit('SET_IS_LOADING', true);
    try {
      const product = await agent.products.details(id);
      commit('SET_PRODUCT', product);
      commit('SET_IS_LOADING', false);
    } catch (error) {
      console.log(error);
      commit('SET_IS_LOADING', false);
    }
  },

  fetchProducts: async ({ commit }) => {
    commit('SET_IS_LOADING', true);
    try {
      const products = await agent.products.all();
      commit('SET_PRODUCTS', products);
      commit('SET_IS_LOADING', false);
    } catch (error) {
      console.log(error);
      commit('SET_IS_LOADING', false);
    }
  },
};
