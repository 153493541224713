import agent from '../../api/agent';
import router from '../../router/index';

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export default {
  login: async ({ commit }, credentials) => {
    try {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }

      const { token } = await agent.users.login(credentials);
      localStorage.setItem('token', token);
      commit('SET_TOKEN', token);

      const { userId, orderId, role, storeId } = parseJwt(token);

      console.log(userId, orderId, role, storeId);

      commit('SET_ORDER_ID', orderId);
      commit('SET_USER_ID', userId);
      commit('SET_ROLE', role);
      commit('SET_STORE_ID', storeId);

      commit('SET_AUTHENTICATED', true);
      commit('SET_TOKEN_IS_EXPIRED', false);

      if (token && role === 'Report') {
        router.push('/report/status');
      } else if (token && role === 'Service') {
        router.push('/service/search-order');
      }
    } catch (error) {
      commit('SET_AUTH_ERRORS_TITLE', error.response.data.title);
      commit('SET_AUTH_ERRORS_DESCRIPTION', error.response.data.detail);
    }
  },

  // autoLogin: ({commit}, token) => {
  //     const {userId, orderId, role, storeId, exp } = parseJwt(token);
  //     const expDate = new Date(exp * 1000);
  //     const tokenIsExpired = expDate < Date.now();
  //     if (tokenIsExpired) {
  //         // localStorage.removeItem('token');
  //         // commit('SET_AUTHENTICATED', false);
  //         // commit('SET_TOKEN_IS_EXPIRED', true);
  //     } else {
  //         commit('SET_ORDER_ID', orderId);
  //         commit('SET_USER_ID', userId);
  //         commit('SET_ROLE', role);
  //         commit('SET_STORE_ID', storeId);
  //         commit('SET_AUTHENTICATED', true);
  //         commit('SET_TOKEN_IS_EXPIRED', false);
  //     }
  //     if (role === 'Report') {
  //         router.push('/report/status');
  //     } else if (role === 'Service') {
  //         router.push('/service/search-order');
  //     }
  // },

  // logout: ({commit}) => {
  //     localStorage.removeItem('token');
  //     commit('SET_USER_ID', null);
  //     commit('SET_AUTHENTICATED', false);
  //     router.push('/')
  // },

  fetchUserDetails: async ({ commit }, id) => {
    try {
      const userDetails = await agent.users.userDetails(id);
      commit('SET_USER_DETAILS', userDetails);
    } catch (error) {
      console.log(error.response);
    }
  },

  updateUserDetails: async ({ commit }, userDetails) => {
    try {
      await agent.users.updateUser(userDetails);
      commit('SET_USER_DETAILS', userDetails);
    } catch (error) {
      console.log(error.response);
    }
  },

  removeUser: async ({ commit }) => {
    try {
      await agent.users.removeUser();
      commit('REMOVE_USER');
      commit('SET_ORDER', null);
      commit('SET_ORDER_ID', null);
      commit('SET_AUTHENTICATED', false);
      router.push('/');
    } catch (error) {
      console.log(error.response);
    }
  },

  changePassword: async ({ commit }, credentials) => {
    try {
      await agent.users.changePassword(credentials);
      commit('SET_PASSWORD', credentials.changedPassword);
    } catch (error) {
      console.log(error.response);
    }
  },

  setFirstname: ({ commit }, firstName) => {
    commit('SET_FIRST_NAME', firstName);
  },

  setLastname: ({ commit }, lastName) => {
    commit('SET_LAST_NAME', lastName);
  },

  setLoyaltyCardNumber: ({ commit }, loyaltyCardNumber) => {
    commit('SET_LOYALTY_CARD_NUMBER', loyaltyCardNumber);
  },

  setDigitalCollector: ({ commit }, option) => {
    commit('SET_DIGITAL_COLLECTOR', option);
  },

  setEmail: ({ commit }, email) => {
    commit('SET_EMAIL', email);
  },

  setEmailConfirmation: ({ commit }, emailConfirmation) => {
    commit('SET_EMAIL_CONFIRMATION', emailConfirmation);
  },

  setPassword: ({ commit }, password) => {
    commit('SET_PASSWORD', password);
  },

  setPasswordConfirmation: ({ commit }, confirmation) => {
    commit('SET_PASSWORD_CONFIRMATION', confirmation);
  },

  setPhone: ({ commit }, phone) => {
    commit('SET_PHONE', phone);
  },

  setMobile: ({ commit }, mobile) => {
    commit('SET_MOBILE', mobile);
  },

  setUserId: ({ commit }, userId) => {
    commit('SET_USER_ID', userId);
  },

  setAuthenticated: ({ commit }, status) => {
    commit('SET_AUTHENTICATED', status);
  },

  setTerms: ({ commit }, userId) => {
    commit('SET_TERMS', userId);
  },

  setPrivacyPolicy: ({ commit }, userId) => {
    commit('SET_PRIVACY_POLICY', userId);
  },

  resetAuthErrors: ({ commit }) => {
    commit('SET_AUTH_ERRORS_TITLE', null);
    commit('SET_AUTH_ERRORS_DESCRIPTION', null);
  },
};
