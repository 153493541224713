<template>
  <div id="app">
    <div
      class="fixed-container"
      v-if="isSpinnerLoading"
    >
      <LoadingSpinner />
    </div>
    <div
      v-if="!isAccessible"
      class="accessible-warning-screen"
      v-text="accessibilityMessage"
    />
    <div v-else-if="textsLoaded">
      <AppHeader />
      <router-view />
      <AppFooter />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import AppHeader from '@/components/navigation/AppHeader';
import AppFooter from '@/components/navigation/AppFooter';
import agent from './api/agent';

export default {
  name: 'App',
  components: { LoadingSpinner, AppHeader, AppFooter },
  data() {
    return {
      isSpinnerLoading: true,
      textsLoaded: false,
      isAccessible: false,
      accessibilityMessage: '',
    };
  },
  created() {
    this.checkAccessibility();
  },

  methods: {
    initializeApp() {
      this.$store.dispatch('fetchLanguages');
      this.$store.dispatch('fetchTexts').then(() => {
        this.textsLoaded = true;
      });
      this.$store.dispatch('fetchProducts');
      this.$store.dispatch('fetchSettings');

      const token = localStorage.getItem('token');

      if (token) {
        // this.$store.dispatch('autoLogin', token);
        const parsedToken = this.parseJwt(token);

        const userId = parsedToken.userId;
        const role = parsedToken.role;
        const storeId = parsedToken.storeId;
        const exp = parsedToken.exp;
        // const tokenId = parsedToken.tokenId;

        const expDate = new Date(exp * 1000);
        const tokenIsExpired = expDate < Date.now();

        if (tokenIsExpired) {
          localStorage.removeItem('token');
          this.$store.commit('SET_AUTHENTICATED', false);
          this.$store.commit('SET_TOKEN_IS_EXPIRED', true);
        } else {
          // this.$store.commit('SET_ORDER_ID', orderId);
          this.$store.commit('SET_USER_ID', userId);
          this.$store.commit('SET_ROLE', role);
          this.$store.commit('SET_STORE_ID', storeId);
          this.$store.commit('SET_AUTHENTICATED', true);
          this.$store.commit('SET_TOKEN_IS_EXPIRED', false);
        }
        if (role === 'Report') {
          this.$router.push('/report/status');
        } else if (role === 'Store') {
          this.$router.push('/product-catalog');
        } else if (role === 'Service') {
          this.$router.push('/service/search-order');
        }
      }

      window.addEventListener('load', this.onWindowLoad);
    },

    checkAccessibility() {
      let urlParams = new URLSearchParams(window.location.search);
      const previewCode = urlParams.get('previewCode');
      if (previewCode) {
        localStorage.setItem('previewCode', previewCode);
      } else {
        localStorage.removeItem('previewCode'); // Ensure no old preview code is enabled
      }
      agent.accessibility.getAccessibility().then((response) => {
        this.isSpinnerLoading = false;
        this.isAccessible = response?.isAccessible;
        this.accessibilityMessage = response?.message;
        if (response.isAccessible) {
          this.initializeApp();
        }
      });
    },

    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      return JSON.parse(jsonPayload);
    },
    onWindowLoad() {
      this.isSpinnerLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

@import '@/global-styles/mixins';
@import '@/global-styles/variables';
@import '@/global-styles/cre8ion-simplegrid';
@import '@/global-styles/flex';
@import '@/global-styles/default';
@import '@/global-styles/forms';
</style>

<style scoped>
.accessible-warning-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 3rem;
  font-weight: 700;
}
</style>
