import Vue from 'vue';
import Vuex from 'vuex';
import agent from '../api/agent';
import { languagesModule } from './languages/index';
import { productsModule } from './products/index';
import { ordersModule } from './orders/index';
import { storesModule } from './stores/index';
import { accountModule } from './account/index';
import { textsModule } from './texts/index';
import { settingsModule } from './settings/index';
import { pagesModule } from './pages-content/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    languages: languagesModule,
    products: productsModule,
    orders: ordersModule,
    stores: storesModule,
    account: accountModule,
    texts: textsModule,
    settings: settingsModule,
    pages: pagesModule,
  },
  state: {
    isLoading: false,
    meta: {
      title: ' | Tefal',
    },
    recaptchaValidation: false,
  },
  actions: {
    setIsLoading: ({ commit }, payload) => {
      commit('SET_IS_LOADING', payload);
    },

    validateRecaptcha: async ({ commit }, payload) => {
      try {
        const response = await agent.validations.recaptcha({ token: payload });
        commit('SET_RECAPTCHA_VALIDATION', response);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_IS_LOADING: (state, payload) => {
      state.isLoading = payload;
    },

    SET_RECAPTCHA_VALIDATION: (state, payload) => {
      state.recaptchaValidation = payload;
    },
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },

    metaTitle: (state) => {
      return state.meta.title;
    },

    recaptchaValidation: (state) => {
      return state.recaptchaValidation;
    },
  },
});
