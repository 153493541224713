export default {
  settings: (state) => state.settings,

  brandLogoUrl: (state) =>
    state.settings ? state.settings.brandLogoUrl : require('../../assets/images/brandFallbackLogo.png'),
  retailerLogoUrl: (state) =>
    state.settings ? state.settings.retailerLogoUrl : require('../../assets/images/retailerFallbackLogo.png'),

  isEmailVisible: (state) => state.settings.isServiceEmailVisible,
  isEmailRequired: (state) => state.settings.isServiceEmailRequired,

  isFirstNameVisible: (state) => state.settings.isServiceFirstNameVisible,
  isFirstNameRequired: (state) => state.settings.isServiceFirstNameRequired,

  isLastNameVisible: (state) => state.settings.isServiceLastNameVisible,
  isLastNameRequired: (state) => state.settings.isServiceLastNameRequired,

  isPhoneVisible: (state) => state.settings.isServicePhoneVisible,
  isPhoneRequired: (state) => state.settings.isServicePhoneRequired,

  isMobileVisible: (state) => state.settings.isServiceMobileVisible,
  isMobileRequired: (state) => state.settings.isServiceMobileRequired,

  isLoyaltyCardVisible: (state) => state.settings.isLoyaltyCardVisible,
  isLoyaltyCardRequired: (state) => state.settings.isLoyaltyCardRequired,

  isDigitalCollector: (state) => state.settings.isDigitalCollector,

  isVoucherCodeRequired: (state) => state.settings.isVoucherCodeRequired,

  productHasCoupon: (state) => state.settings.productHasCoupon,

  programStep: (state) => state.settings.programStep,
};
