import requests from '../requests';
import { documentDownloadHeadersConfig } from '../../utils/downloadService';

const service = {
  searchOrder: (query) =>
    requests.get(`/service/searchorder?number=${query.number}&email=${query.email}&phone=${query.phone}`),
  confirmOrder: (orderData) => requests.post('/service/confirmorder', orderData),
  cancelOrder: (id) => requests.post('/service/cancelorder', id),
  saveProduct: (order) => requests.post('/service/saveproduct', order),
  resendConfirmation: (orderNumber) => requests.get(`/service/resendconfirmationmail/${orderNumber}`),
  printOrder: (orderNumber) => requests.get(`/service/printorder/${orderNumber}`, documentDownloadHeadersConfig),
  registerUser: (userData) => requests.post('/service/registeruser', userData),
};

export default service;
