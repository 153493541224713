export default {
  SET_FIRST_NAME: (state, firstName) => {
    state.userData.firstName = firstName;
  },

  SET_LAST_NAME: (state, lastName) => {
    state.userData.lastName = lastName;
  },

  SET_LOYALTY_CARD_NUMBER: (state, loyaltyCardNumber) => {
    state.userData.loyaltyCardNumber = loyaltyCardNumber;
  },

  SET_DIGITAL_COLLECTOR: (state, option) => {
    state.userData.digitalCollector = option;
  },

  SET_EMAIL: (state, email) => {
    state.userData.email = email;
  },

  SET_EMAIL_CONFIRMATION: (state, emailConfirmation) => {
    state.userData.emailConfirmation = emailConfirmation;
  },

  SET_PASSWORD: (state, password) => {
    state.userData.password = password;
  },

  SET_PASSWORD_CONFIRMATION: (state, confirmation) => {
    state.userData.passwordConfirmation = confirmation;
  },

  SET_PHONE: (state, phone) => {
    state.userData.phone = phone;
  },

  SET_MOBILE: (state, mobile) => {
    state.userData.mobile = mobile;
  },

  SET_TERMS: (state, payload) => {
    state.userData.terms = payload;
  },

  SET_PRIVACY_POLICY: (state, payload) => {
    state.userData.privacyPolicy = payload;
  },

  SET_TOKEN: (state, token) => {
    state.token = token;
  },

  SET_USER_ID: (state, userId) => {
    state.userId = userId;
  },

  SET_ROLE: (state, role) => {
    state.role = role;
  },

  SET_STORE_ID: (state, storeId) => {
    state.storeId = storeId;
  },

  SET_AUTHENTICATED: (state, status) => {
    state.authenticated = status;
  },

  SET_AUTH_ERRORS_TITLE: (state, error) => {
    state.authErrorsTitle = error;
  },

  SET_AUTH_ERRORS_DESCRIPTION: (state, error) => {
    state.authErrorsDescription = error;
  },

  SET_TOKEN_IS_EXPIRED: (state, payload) => {
    state.tokenIsExpired = payload;
  },

  SET_USER_DETAILS: (state, userDetails) => {
    state.userData.firstName = userDetails.firstName;
    state.userData.lastName = userDetails.lastName;
    state.userData.email = userDetails.email;
    state.userData.phone = userDetails.phone;
    state.userData.mobile = userDetails.mobile;
    state.userData.digitalCollector = userDetails.isDigitalSaver;
  },

  REMOVE_USER: (state) => {
    state.userData.firstName = '';
    state.userData.lastName = '';
    state.userData.loyaltyCardNumber = '';
    state.userData.digitalCollector = false;
    state.userData.email = '';
    state.userData.emailConfirmation = '';
    state.userData.password = '';
    state.userData.passwordConfirmation = '';
    state.userData.phone = '';
    state.userData.mobile = '';
    state.userData.terms = false;
    state.userData.privacyPolicy = false;
  },
};
